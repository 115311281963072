export default class Front {
    static get_launcher() {
        return {};
    }

    static get_modules() {
        return {
            front_firstly: [],
            front        : []
        };
    }
}