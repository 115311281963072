export default class Routeur {
    static get() {
        return {
            schemas: {
                home: "",
                /*search: {
                    format: new RegExp('^recherche$'),
                    class_name: 'Search'
                },*/
                page: new RegExp('^[-A-Za-z0-9]{1,}$'),
                single: new RegExp('^[-A-Za-z0-9]{1,}/[-A-Za-z0-9]{1,}$')
            }
        };
    };
}