import {Route} from "hind/core";
import tpl_home from "../views/routes/home";

const config = {
    meta: {
        description: "La HOME DE A&B !"
    }
};

export default class Home extends Route {
    constructor(args) {
        args = {
            Ourl_info: args.Ourl_info
        };

        super(args, config);
    }

    get_content_data() {
        return {
            view: tpl_home(this._json)
        };
    }

    get_content_data_first_loading_from_client() {
        return {};
    }

    run_after_rendered_view() {

    }
}