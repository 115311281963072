import {Omodels} from "hind/core";

export default class home {
    constructor(query_args) {
        this._query_args = query_args;
    }

    get() {
        return new Promise(
            (resolve, reject) => {
                resolve(true);
                // EXEMPLE WITH WORDPRESS API
                /* Omodels.get_from_driver("Wordpress", "Page", this._query_args).then(
                     res => {
                         let Owp_page = res;

                         Owp_page.load_config("wp");

                         const home_data = Owp_page.get();

                         Promise.all([home_data]).then(
                             values => {
                                 Owp_page = null;

                                 resolve(values[0]);
                             },
                             reason => {
                                 Owp_page = null;

                                 reject(reason);
                             }
                         );
                     },
                     error => {
                         reject(error);
                     }
                 );*/
            }
        );
    }

    destroy() {
    }
}