export default class View {
    static get() {
        return {
            title                   : "Zi PortaL",
            default_meta_description: "Portail des applications de Matthieu Barbaresco",
            menus                   : {
                header_menu: {
                    items: []
                }
            }
        };
    }
}