import {Route} from "hind/core";
import tpl_contact from "../views/routes/contact";

/**
 * config type par défaut - en dehors de la 404 et de la Home
 */
const config = {
    use_model: true,
    title: "Contact",
    meta: {
        description: "Contactez My Pretty App"
    }
};

export default class Contact extends Route {
    constructor(args) {
        args = {
            Ourl_info: args.Ourl_info
        };

        super(args, config);
    }

    get_content_data() {
        return {
            view: tpl_contact()
        };
    }

    get_content_data_first_loading_from_client() {
        return {};
    }

    run_after_rendered_view() {
        /* let self = this;
         let Obutton_contact_submit = document.getElementById("button_contact_submit");

         Obutton_contact_submit.addEventListener("click", function (e) {
             e.stopPropagation();

             self.push();
         });*/
    }

    push() {
        //console.log("Routes/Contact - push");
        /*    let Ohelper_string = this._Ohelpers_factory.get("String");

            const contact_data = {
                'first_name': Ohelper_string.get_html_entities_encoded(document.getElementById("contact_first_name_field").value),
                'last_name': Ohelper_string.get_html_entities_encoded(document.getElementById("contact_last_name_field").value),
                'email': document.getElementById("contact_email_field").value,
                'subject': Ohelper_string.get_html_entities_encoded(document.getElementById("contact_subject_field").value),
                'message': Ohelper_string.get_html_entities_encoded(document.getElementById("contact_message_field").value),
                'send_copy_to_sender': document.getElementById("send_copy_to_sender").checked
            };
            //console.log(contact_data);
            Ohelper_string = null;
            //console.log(this.is_form_validated(contact_data));
            if (this.is_form_validated(contact_data)) {
                this._Omodel.push(contact_data).then(
                    response => {
                        //console.log(response.status);
                        this.print_message_after_sending(response.status);
                    },
                    error => {
                        console.log(error);
                    }
                );
            }*/
    }

    print_message_after_sending(status) {
        if (status) {
            console.log("Votre message a bien été envoyé.");
        } else {
            console.log("Erreur du serveur. Votre message n'a pu être envoyé.");
        }
    }

    is_form_validated(contact_data) {
        /*let is_valid = true;

        const error_class = "error_empty";
        const fields_to_test = {
            first_name: "input",
            last_name: "input",
            email: "input",
            subject: "input",
            message: "textarea"
        };

        for (let field in fields_to_test) {
            //console.log(fields_to_test[field]);
            const type_of_value = field;
            const value = contact_data[type_of_value];
            const input_id = `contact_${type_of_value}_field`;

            if (fields_to_test[field] === "input" || fields_to_test[field] === "textarea") {
                if (value.charAt(0) === "") {
                    if (fields_to_test[field] === "input") {
                        document.getElementById(input_id).parentNode.classList.add(error_class);
                    } else {
                        document.getElementById(input_id).classList.add(error_class);
                    }
                    //console.log(type_of_value + " ::: " + value);
                    is_valid = false;
                } else {
                    if (type_of_value === 'email') {
                        const filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

                        if (filter.test(value))
                            document.getElementById(input_id).parentNode.classList.remove(error_class);
                        else {
                            document.getElementById(input_id).parentNode.classList.add(error_class);

                            is_valid = false;
                        }
                    } else {
                        if (fields_to_test[field] === "input") {
                            document.getElementById(input_id).parentNode.classList.remove(error_class);
                        } else {
                            document.getElementById(input_id).classList.remove(error_class);
                        }
                    }
                }
            }
        }

        return is_valid;*/
    }
}