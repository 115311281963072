import {Omodels} from "hind//core";

export default class Contact {
    constructor(query_args) {
        this._query_args = query_args;
    }

    get() {
        return new Promise(
            (resolve, reject) => {
                resolve(true);
                // EXEMPLE WITH WORDPRESS API
                /* Omodels.get_from_driver("Wordpress", "Page", this._query_args).then(
                     res => {
                         let Owp_page = res;

                         Owp_page.load_config("wp");

                         const contact_data = Owp_page.get();

                         Promise.all([contact_data]).then(
                             values => {
                                 Owp_page = null;

                                 resolve(values[0]);
                             },
                             reason => {
                                 Owp_page = null;

                                 reject(reason);
                             }
                         );
                     },
                     error => {
                         reject(error);
                     }
                 );*/
            }
        );
    }

    push(contact_data) {
        // EXEMPLE WITH WORDPRESS API
        /* let Owp_contact = Omodels.get_model_from_driver("Wordpress", "Contact");

         Owp_contact.load_config("wp");

         return new Promise(
             (resolve, reject) => {
                 const push = Owp_contact.push(contact_data);

                 Promise.all([push]).then(
                     values => {
                         Owp_contact = null;

                         resolve(values[0]);
                     },
                     reason => {
                         Owp_contact = null;

                         reject(reason);
                     }
                 );
             }
         );*/
    }

    destroy() {
    }
}