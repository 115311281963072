import {Ohelper_view_assets as assets} from "hind/core";

export default function home(json = null) {
    return `
    <div class="applications">
        <div class="applications__application">
            <a href="https://cv.barbares.co" class="external_link">
                    ${assets.get({
        name: "cv.png",
        alt : "En-tête du CV web de Matthieu Barbaresco"
    }, "img")}
            </a>
        </div>
    </div>
    `;
}