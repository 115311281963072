export default class Browsing {
    static get() {
        return {
            areas_to_cover: {
                // header: "sidebar", // area_from: "<selector><;reload>"
            },
            external_link_class: "external_link",
            other_browsing_manager_class: "other_browsing_manager",
            markers_links_visiting: {
                selector: "a[current='current']",
                area: "current_area",
                attribute: "current",
                attribute_value: "current",
                link: "current_link"
            }
        };
    }
}