import header from "./header";
import footer from "./footer";
import {Ohelper_view_assets as assets} from "hind/core";

export default function base(data) {
    return `
        <!DOCTYPE html>
        
        <html lang="fr">
        
            <head>
            
                <meta name="description" content="${data.meta.description}" />
            
                <title>${data.title}</title>
                
                ${assets.get(data.styles, "css")}
                ${assets.get("favicon", "favicon")}

                <meta charset="UTF-8" />

                <meta name="viewport" content="initial-scale=1,maximum-scale=1,user-scalable=no" />
            
            </head>
            
            <body class="${data.current_page}" data-current_page="${data.current_page}" data-current_template="${data.template_name}">

                ${header(data)}
                
                <main>
                    <div class="container" id="container" data-current_template="${data.template_name}">
                        ${data.view}
                    </div>
                </main>
                
                ${footer()}
              
                ${assets.get(data.scripts, 'js')}
        
            </body>
        </html>
    `;

};